import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ilogodark from '../../../assets/logo/logodark.svg';
import idivider from '../../../assets/icons/common/divider.svg';

const LogoWithSlogan = ({ showSlogan = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    if (location.pathname === '/') {
      window.location.href = '/';
    } else {
      navigate('/');
    }
  };

  return (
    <div onClick={handleLogoClick} className="flex flex-row items-center gap-2 w-fit h-fit cursor-pointer"> 
      <img src={ilogodark} alt="M2DT Logo" className="h-5" />
      <img 
        src={idivider} 
        alt="divider" 
        className={`h-5 ${showSlogan ? '' : 'hidden xl:block'}`} 
      />
      <span className={`font-inter font-[400] text-sm ${showSlogan ? '' : 'hidden xl:block'}`}>
        Tech that means business
      </span>
    </div>
  );
};

export default LogoWithSlogan;
