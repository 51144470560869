export const validateForm = (formData) => {
    const newErrors = {};
  
    if (!formData.name) newErrors.name = '*Name is required';
    if (!formData.email) {
      newErrors.email = '*Email is required';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = '*Invalid email format';
      }
    }
    if (formData.phoneNumber) {
      const phoneRegex = /^\+?\d+$/;
      if (!phoneRegex.test(formData.phoneNumber)) {
        newErrors.phoneNumber = '*Invalid phone number format';
      }
    }
    if (formData.projectType.length === 0) newErrors.projectType = '*At least one project type is required';
    if (!formData.projectDescription) newErrors.projectDescription = '*Project description is required';
    if (!formData.projectDuration) newErrors.projectDuration = '*Project duration is required';
    if (!formData.startDate) newErrors.startDate = '*Start date is required';
    if (!formData.budgetRange) newErrors.budgetRange = '*Budget range is required';
    if (!formData.consent) newErrors.consent = '*You must consent to proceed';
  
    return newErrors;
  };
  