import isuccesstick from '../assets/icons/common/successtick.svg';

const StatusMessage = ({ statusText }) => {
  if (!statusText) return null;
  
  return (
    <div
      className={`transition-all duration-500 ease-in-out overflow-hidden flex justify-center items-center 
      ${statusText ? 'max-h-20 opacity-100' : 'max-h-0 opacity-0'}`}
    >
      {statusText && (
        <p
          className={`text-middle mt-1 flex flex-row gap-2 items-center 
          ${statusText === 'Sending...'
            ? 'text-blue-500 animate-pulse'
            : statusText.includes('failed') || statusText.includes('error')
              ? 'text-red-500'
              : 'text-green-500'
          }`}
        >
          {statusText}
          {statusText.includes('successfully') && (
            <img src={isuccesstick} alt="Success" className="w-6 h-6" />
          )}
        </p>
      )}
    </div>
  );
};

export default StatusMessage;
