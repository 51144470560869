import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollHandler = () => {
    const location = useLocation();

    useEffect(() => {
        const scrollToSection = (sectionId) => {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        };

        if (location.state?.section) {
            const sectionId = location.state.section.slice(1);
            scrollToSection(sectionId);
        }
        else if (location.hash) {
            const sectionId = location.hash.slice(1);
            scrollToSection(sectionId);
        }
    }, [location]);

    return null;
};

export default ScrollHandler;
