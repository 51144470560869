import React from 'react';

const Layout = ({ children }) => {
  return (
    <div className="min-w-screen h-fit px-6 lg:px-[150px] xl:px-[200px]">
      {children}
    </div>
  );
};

export default Layout;
