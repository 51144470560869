import React from 'react';
import FormInput from './FormInput';
import ErrorMessage from '../ErrorMessage';
import MainButton from '../MainButton';
import CustomDropdown from './CustomDropdown';
import CustomDatePicker from './CustomDatePicker';
import '../../styles/CustomDatePicker.css';
import useContactForm from '../../hooks/useContactForm';
import CustomCheckbox from './CustomCheckbox';
import StatusMessage from '../StatusMessage';

const ContactForm = () => {
  const {
    formData,
    errors,
    statusText,
    resetDropdown,
    handleInputChange,
    handleDropdownChange,
    handleCheckboxChange,
    handleDateChange,
    handleSubmit,
  } = useContactForm();

  const projectTypeOptions = [
    'Mobile App Development',
    'Web Development',
    'Custom Software Development',
    '3rd Party Services - Automation & Monitoring',
    'AI Enabled Solutions',
    'R&D Services (Automation & Monitoring)',
    'Consulting'
  ];

  const projectDurationOptions = ['Under a month', '1-3 Months', 'More than 3 Months'];
  const budgetRangeOptions = ['$2 000 - $5 000', '$5 000 - $10 000', '$10 000 - $20 000', 'Over $20 000'];

  return (
    <form className='rounded-3xl max-w-xl bg-darkblue p-2 md:p-8 lg:p-8 text-white flex flex-col gap-6 font-inter text-[rgba(255, 255, 255, 0.80)] transition-all ease-in-out'>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FormInput
          label="Name"
          type="text"
          name="name"
          placeholder="Name*"
          value={formData.name}
          onChange={handleInputChange}
          error={errors.name}
        />
        <FormInput
          label="Email"
          type="email"
          name="email"
          placeholder="Email*"
          value={formData.email}
          onChange={handleInputChange}
          error={errors.email}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FormInput
          label="Company name"
          type="text"
          name="companyName"
          placeholder="Company name"
          value={formData.companyName}
          onChange={handleInputChange}
          error={errors.companyName}
        />
        <FormInput
          label="Phone number"
          type="text"
          name="phoneNumber"
          placeholder="Phone number"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          error={errors.phoneNumber}
        />
      </div>

      <CustomDropdown
        label="Project Type"
        options={projectTypeOptions}
        placeholder="Project type"
        onChange={(value) => handleDropdownChange('projectType', value)}
        selectedOptions={formData.projectType}
        isMultiselect={true}
        error={errors.projectType}
        reset={resetDropdown}
      />

      <div>
        <textarea
          className={`border bg-darkblue rounded-3xl pl-5 pt-4 pb-4 pr-8 resize-none focus:outline-none 
                      focus:ring-2 focus:ring-blue-500 w-full h-28 
                      ${errors.projectDescription ? 'border-red-500 mt-3 mb-0' : 'border-stroke-1'}`}
          name="projectDescription"
          placeholder="Project Description*"
          value={formData.projectDescription}
          onChange={handleInputChange}
        />
        <ErrorMessage error={errors.projectDescription} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-h-fit">
        <CustomDropdown
          label="Project duration"
          options={projectDurationOptions}
          placeholder="Project duration"
          onChange={(value) => handleDropdownChange('projectDuration', value)}
          error={errors.projectDuration}
          reset={resetDropdown}
        />
        <CustomDatePicker
          selectedDate={formData.startDate}
          onDateChange={handleDateChange}
          error={errors.startDate}
        />
      </div>

      <CustomDropdown
        label="Budget Range"
        options={budgetRangeOptions}
        placeholder="Budget range"
        onChange={(value) => handleDropdownChange('budgetRange', value)}
        error={errors.budgetRange}
        reset={resetDropdown}
      />

      <CustomCheckbox
        id="default-checkbox"
        label="I consent for you to process and store that I've shared with you."
        checked={formData.consent}
        onChange={handleCheckboxChange}
        error={errors.consent}
      />

      <MainButton text={'Send Inquiry'} showIcon={true} className='w-full border-stroke-5 px-8 py-4 bg-bgbtn mt-5' onClick={handleSubmit} />
      <StatusMessage statusText={statusText} />
    </form>
  );
};

export default ContactForm;
