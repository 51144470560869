import { useState } from 'react';
import { validateForm } from '../utils/formValidator';
import { formatDate } from '../utils/dateFormatter';

const useContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    phoneNumber: '',
    projectType: [],
    projectDescription: '',
    projectDuration: '',
    startDate: null,
    budgetRange: null,
    consent: false,
  });

  const [errors, setErrors] = useState({});
  const [statusText, setStatusText] = useState('');
  const [resetDropdown, setResetDropdown] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleDropdownChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: Array.isArray(value) ? value : [value],
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleCheckboxChange = (checked) => {
    setFormData((prevData) => ({
      ...prevData,
      consent: checked,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, consent: false }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      startDate: date,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, startDate: null }));
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      companyName: '',
      phoneNumber: '',
      projectType: [],
      projectDescription: '',
      projectDuration: '',
      startDate: null,
      budgetRange: null,
      consent: false,
    });

    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setStatusText('');
      return;
    }

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        formDataToSend.append(key, value[value.length - 1]);
      } else if (key === 'startDate') {
        formDataToSend.append(key, formatDate(value));
      } else {
        formDataToSend.append(key, value);
      }
    });
    formDataToSend.append("access_key", process.env.REACT_APP_WEB3_FORM_PUBLIC_KEY);

    setStatusText('Sending...');
    try {
      const response = await fetch(`${process.env.REACT_APP_WEB3_FORM_API_URL}/submit`, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.success) {
        setStatusText(data.message);
        resetForm();
        setResetDropdown(true);
        setErrors({});
        setTimeout(() => setResetDropdown(false), 0);
      } else {
        setStatusText(data.message || "An error occurred while submitting the form.");
      }
    } catch (error) {
      setStatusText("Submission failed. Please try again.");
    }
  };

  return {
    formData,
    errors,
    statusText,
    resetDropdown,
    handleInputChange,
    handleDropdownChange,
    handleCheckboxChange,
    handleDateChange,
    handleSubmit,
  };
};

export default useContactForm;
