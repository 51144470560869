import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import iarrow from '../../assets/icons/common/arrow.svg';
import CustomCheckbox from './CustomCheckbox';
import ErrorMessage from '../ErrorMessage';

const CustomDropdown = ({ label, options, placeholder, onChange, isMultiselect = false, error, reset }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    if (label === 'Project Type') {
      const newSelectedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((selected) => selected !== option)
        : [...selectedOptions, option];
      setSelectedOptions(newSelectedOptions);
      onChange(newSelectedOptions.join(', '));
    } else {
      setSelectedOptions([option]);
      onChange(option);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (reset) {
      setSelectedOptions([]);
    }
  }, [reset, onChange]);

  return (
    <div className={`w-full relative ${error ? 'mb-2 mt-2' : ''}`} ref={dropdownRef}>
      <div
        onClick={toggleDropdown}
        className={`border ${error ? 'border-red-500' : 'border-stroke-1'} break-words bg-darkblue rounded-3xl pl-5 pt-4 pb-4 pr-5 cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-between`}  // Added flex & justify-between here
        style={{ width: '100%', minHeight: '56px', overflow: 'hidden' }}
      >
        <span>{selectedOptions.length > 0 ? selectedOptions.join(', ') : placeholder}</span>

        <span className="flex items-center justify-center">
          <img
            src={iarrow}
            alt="arrow"
            className={`min-w-6 w-6 h-6 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
          />
        </span>
      </div>


      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="absolute z-10 mt-1 bg-lightblue border border-stroke-1 rounded-3xl shadow-lg w-full"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="w-full rounded-3xl p-1">
              {options.map((option) => (
                <div
                  key={option}
                  onClick={() => handleOptionClick(option)}
                  className={`px-5 py-2 rounded-3xl hover:bg-blue cursor-pointer w-full ${selectedOptions.includes(option) ? 'bg-blue' : ''}`}
                >
                  {isMultiselect ? (
                    <CustomCheckbox
                      id={option}
                      label={option}
                      checked={selectedOptions.includes(option)}
                      onChange={(checked) => handleOptionClick(option, checked)}
                    />
                  ) : (
                    <span>{option}</span>
                  )}
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <ErrorMessage error={error} className={'absolute'} />
    </div>
  );
};

export default CustomDropdown;
