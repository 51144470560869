import React from 'react';
import iarrowgradient from '../assets/icons/common/arrowgradient.svg';
import { useNavigate } from 'react-router-dom';

const MainButton = ({
  text,
  showIcon = false,
  className = 'bg-[rgba(255,255,255,0.20)] border-stroke-2 w-fit',
  onClick,
  scrollToId,
}) => {
  const navigate = useNavigate();
  const handleClick = (event) => {
    if (scrollToId) {
      navigate('/', { state: { section: `#${scrollToId}` } });
      window.location.hash = `#${scrollToId}`;
    } else if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      className={`flex items-center justify-between gap-[10px] font-[500] rounded-[100px] px-4 py-3 backdrop-blur-[3px] border m-0 transition duration-300 ${className}`}
      onClick={handleClick}
    >
      {text && <span className='gradient-text'>{text}</span>}
      {showIcon && (
        <img src={iarrowgradient} alt='Arrow' className='w-6 h-6 text-center bg-bgbtn rounded-full p-1' />
      )}
    </button>
  );
};

export default MainButton;
