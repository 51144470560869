import React from 'react';
import SectionTitle from '../../components/SectionTitle';
import ServiceCard from '../../components/Cards/ServiceCard';
import imobileapp from '../../assets/icons/services/mobileapp.svg';
import icustomsoftware from '../../assets/icons/services/customsoftware.svg';
import icustomweb from '../../assets/icons/services/customweb.svg';
import iai from '../../assets/icons/services/ai.svg';
import iautomation from '../../assets/icons/services/automation.svg';
import ipoc from '../../assets/icons/services/poc.svg';

const Services = () => {
    const servicesData = [
        {
            icon: icustomsoftware,
            title: 'Custom Software Development',
            description: 'Whether you need enterprise solutions or niche applications, we create software customized to streamline your operations, increase efficiency and solve complex business challenges.'
        },
        {
            icon: icustomweb,
            title: 'Custom Web Development',
            description: 'We build responsive, scalable websites and web applications that enhance online presence. Using the latest tech, we ensure great user experiences on all devices.'
        },
        {
            icon: imobileapp,
            title: 'Mobile App Development',
            description: 'From idea to launch, we create user-friendly apps for iOS and Android that engage your audience and drive results.'
        },
        {
            icon: iautomation,
            title: '3rd Party - Automation & Monitoring',
            description: 'By leveraging our expertise in resale markets, we provide specialized automation and monitoring services to enhance customer experience. Through data analysis and gathering valuable insights, we empower you to make clever buying and selling decisions.'
        },
        {
            icon: ipoc,
            title: 'R&D Services (Hardware PoC and Prototyping)',
            description: 'We help bring your hardware ideas to life - from initial sketches to working prototypes, we bridge the gap between concept and production-ready hardware. Our services include rapid prototyping of hardware concepts, developing Proof of Concept (PoC) models, and iterative design and testing.'   
        },
        {
            icon: iai,
            title: 'Custom AI-Enabled Solutions',
            description: 'We integrate existing AI services into your custom software, creating smart applications that automate tasks and enhance user experiences - no in-house AI expertise required.'
        },
    ];

    return (
        <section id='our-services'>
            <SectionTitle title='Our Services' className='mb-6' />

            <div className='mb-16'>
                <div className='mb-5'>
                    <h1 className='heading2'>Empowering your</h1>
                    <h1 className='heading2'>business with our</h1>
                    <h1 className='heading2'>know-how</h1>
                </div>

                <div className='text-normal'>
                    <p>Our team of experts provide guidance and</p>
                    <p>drive digital transformation in various fields.</p>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                {servicesData.map((service, index) => (
                    <ServiceCard
                        key={index}
                        icon={service.icon}
                        title={service.title}
                        description={service.description}
                    />
                ))}
            </div>
        </section>
    );
}

export default Services;
