import React from 'react';
import SectionLayout from '../../layout/SectionLayout/SectionLayout';
import igraycircle from '../../assets/icons/common/graycircle.svg';
import Services from './Services';
import Approach from './Approach';

const ServicesAndApproach = () => {
    return (
        <section
            className={`bg-white w-full h-full md:rounded-3xl lg:rounded-3xl -mt-5 pt-20 pb-20 z-20 relative overflow-hidden`}>

            <img
                src={igraycircle}
                alt="Gradient Circle"
                className='absolute right-0 top-1/2 transform translate-x-[55%] -translate-y-[20%] hidden md:block lg:block'
                style={{ width: '60%', height: 'auto', transform: 'translateY(-20%)', marginRight: '-30%' }}
            />

            <SectionLayout>
                <div className='flex flex-col gap-20 relative z-10'>
                    <Services />
                    <Approach />
                </div>
            </SectionLayout>
        </section>

    );
};

export default ServicesAndApproach;
