const ErrorMessage = ({ error }) => {
    return (
        <div
            className={`transition-all duration-500 ease-in-out overflow-hidden ${error ? 'max-h-20 opacity-100' : 'max-h-0 opacity-0'}`}
        >
            {error && (
                <p className="text-red-500 text-sm mt-1">
                    {error}
                </p>
            )}
        </div>
    );
};

export default ErrorMessage;