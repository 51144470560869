import React from 'react';
import SectionLayout from '../../layout/SectionLayout/SectionLayout';
import mainbg from '../../assets/images/mainbg.jpg';
import MainButton from '../../components/MainButton';

const HeroSection = () => {
    return (
        <section
            className="min-w-full min-h-screen bg-cover bg-center bg-no-repeat flex items-center"
            style={{ backgroundImage: `url(${mainbg})` }}
        >
            <SectionLayout>
                <div className='flex flex-col gap-[24px]'>
                    <div>
                        <h1 className='heading1'>Innovative Solutions</h1>
                        <br />
                        <h1 className='heading1'>for Your Digital Needs</h1>
                    </div>
                    <div>
                        <p className='text-hero'>Specialized in Product-Driven Development:</p>
                        <p className='text-hero'>From Product Strategy to Custom Software</p>
                    </div>
                    <div>
                        <MainButton text='Discover Our Expertise' showIcon={true} scrollToId="contact-us" />
                    </div>
                </div>
            </SectionLayout>
        </section>
    );
};

export default HeroSection;
