import React from 'react';
import ErrorMessage from '../ErrorMessage';
import itick from '../../assets/icons/common/tick.svg';

const CustomCheckbox = ({ id, label, onChange, checked, error }) => {
  const handleCheckboxChange = () => {
    onChange(!checked);
  };

  return (
    <div className={`flex flex-col relative ${error ? 'mb-2 mt-2' : ''}`}>
      <div className="flex items-center gap-3 min-w-full">
        <div
          className={`min-w-[24px] min-h-[24px] flex items-center justify-center border border-stroke-1 rounded cursor-pointer 
          ${checked ? 'bg-blue-600 border-blue-600' : 'bg-darkblue'}
          transition duration-200 ease-in-out`}
          role="checkbox"
          aria-checked={checked}
          tabIndex={0}
          onClick={handleCheckboxChange}
          onKeyPress={(e) => { if (e.key === 'Enter') handleCheckboxChange(); }}
        >
          {checked && (
            <img
              src={itick}
              alt="Tick icon"
              className="w-5 h-5"
            />
          )}
        </div>
        <label htmlFor={id} className="text-small text-white cursor-pointer">
          {label}
        </label>
      </div>
      <ErrorMessage error={error} />
    </div>
  );
};

export default CustomCheckbox;
