import React from 'react';
import SectionLayout from '../../layout/SectionLayout/SectionLayout';
import SectionTitle from '../../components/SectionTitle';
import igradientcircle from '../../assets/icons/common/gradientcircle.svg';
import ContactForm from '../../components/ContactForm/ContactForm';

const Ending = () => {
    return (
        <section id='contact-us' className='relative -mt-5 overflow-hidden'>
            <div className='bg-blue w-full h-full pb-60 pt-20 flex relative z-0'>
                <SectionLayout>
                    <div className='relative z-10 flex flex-col md:flex-row gap-5'>
                        <div>
                            <SectionTitle title='Get in touch' className='mb-6' />

                            <div className='mb-16 text-white'>
                                <div className='mb-5 text-white'>
                                    <h1 className='heading2'>Let us know more</h1>
                                    <h1 className='heading2'>about your project!</h1>
                                </div>

                                <div className='text-normal text-white max-w-md'>
                                    <p>If you have a question for us or would like to work with us, send us an email on info@m2dtconsulting.com, or fill the form!</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <ContactForm />
                        </div>
                    </div>
                </SectionLayout>

                <img
                    src={igradientcircle}
                    alt="Circle"
                    className="absolute bottom-0 left-0 transform -translate-x-[20%] translate-y-[50%] w-3/4"
                    style={{ zIndex: 0 }}
                />
            </div>
        </section>
    );
}

export default Ending;
