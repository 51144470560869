import React from 'react';
import SectionLayout from '../../layout/SectionLayout/SectionLayout';
import Technology from './Technology';
import AboutUs from './AboutUs';

const TechnologyAndAboutUs = () => {
  return (
    <section className='relative z-20 w-full h-fit pt-20 pb-20 bg-white md:rounded-3xl lg:rounded-3xl -mt-5'>
      <SectionLayout>
        <div className='flex flex-col gap-20'>
          <Technology />
          <AboutUs />
        </div>
      </SectionLayout>
    </section>
  )
}

export default TechnologyAndAboutUs