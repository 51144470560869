import React from 'react';
import SectionLayout from '../layout/SectionLayout/SectionLayout';

const PrivacyPolicyPage = () => {
    return (
        <section className='pt-[100px] pb-[100px] text-normal'>
            <SectionLayout>
                <div className='flex flex-col gap-3 max-w-4xl'>
                    <h1 className='heading2'>Privacy Policy</h1>
                    <p>Last updated: 29/07/2024</p>
                    <p>
                        M2DT ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you visit our website or use our services.
                    </p>

                    <h2 className='heading3'>Information We Collect</h2>
                    <p>We collect information in the following ways:</p>
                    <ol className='list-decimal pl-5'> 
                        <li>
                            <strong>Information you provide:</strong>
                            <p className='mt-1'>When you fill out our contact form, we collect your name, email address, and any project details you choose to share with us.</p>
                        </li>
                        <li>
                            <strong>Information collected automatically:</strong>
                            <p className='mt-1'>We use Google Analytics to collect data about your interactions with our website, including pages visited, time spent on site, and other usage statistics.</p>
                        </li>
                    </ol>

                    <h2 className='heading3'>How We Use Your Information</h2>
                    <p>We use the collected information for the following purposes:</p>
                    <ul className='list-disc pl-5'>
                        <li>To respond to your inquiries and provide requested services</li>
                        <li>To improve our website and services</li>
                        <li>To analyze website traffic and user behavior</li>
                        <li>To communicate with you about our services and promotions</li>
                    </ul>

                    <h2 className='heading3'>Data Protection</h2>
                    <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.</p>

                    <h2>Third-Party Services</h2>
                    <p>We use Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses cookies to help us analyze how users interact with our website. The information generated by the cookie about your use of the website will be transmitted to and stored by Google on servers in the United States.</p>
                    <p>We do not share other personal information with any other third parties, except as required by law or as necessary to provide you with our services. We will never sell your personal information to third parties.</p>

                    <h2 className='heading3'>Your Rights</h2>
                    <p>You have the right to:</p>
                    <ul className='list-disc pl-5'>
                        <li>Access your personal data</li>
                        <li>Request correction of inaccurate data</li>
                        <li>Request deletion of your data</li>
                    </ul>

                    <h2 className='heading3'>Changes to This Privacy Policy</h2>
                    <p>We may update this Privacy Policy from time to time. The date of change will be updated at the top of this document.</p>

                    <h2 className='heading3'>Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, please contact us at <a href='mailto:info@m2dtconsulting.com'>info@m2dtconsulting.com</a>.</p>
                </div>
            </SectionLayout>
        </section>
    );
}

export default PrivacyPolicyPage;
