import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        if (!location.state?.section) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return null;
};
