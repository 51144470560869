import React from 'react';
import SectionTitle from '../../components/SectionTitle';
import iaboutus from '../../assets/images/aboutus.svg';

const AboutUs = () => {
  return (
    <section id='about-us'>
      <div className='flex flex-col items-start md:flex-row gap-10'>

        <img
          src={iaboutus}
          alt="About Us"
          className='w-auto max-w-2/3 min-w-[250px] md:min-w-[300px]'
        />

        <div className='flex-1 overflow-hidden min-w-[250px] md:min-w-[300px]'>
          <SectionTitle title='About us' className='mb-6' />

          <div className='mb-6 lg:mb-16'>
            <div className='mb-5'>
              <h1 className='heading2'>Committed to Your</h1>
              <h1 className='heading2'>Success</h1>
            </div>

            <div className='w-full text-normal break-words max-h-full max-w-6xl'>
              <p>
                We're a team of experienced software developers passionate about solving tech challenges. Our broad expertise helps us stay ahead of industry trends and embrace new technologies, giving our clients a competitive edge.
              </p>
              <br />
              <p>
                We've worked with both small startups and large corporations across various industries. This diverse experience equips us to handle any tech challenge you bring our way.
              </p>
              <br />
              <p>
                Whether you're just starting out or are well-established, we can help. Simple or complex, big or small—we're here to turn your software ideas into reality.
              </p>
              <br />
              <p>
                Let's work together to make your business better through technology!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
