import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ilogolight from '../../assets/logo/logolight.svg';
import ilogodark from '../../assets/logo/logodark.svg';
import CookiePopup from '../../components/Popups/CookiePopup';

const Footer = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const location = useLocation();

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const colorMap = {
        '/': {
            textColor: 'text-white',
            logo: ilogolight,
        },
        '/privacy-policy': {
            textColor: 'text-black',
            logo: ilogodark,
        },
    };

    const { textColor, logo } = colorMap[location.pathname] || { textColor: 'text-white', logo: ilogolight };

    return (
        <>
            <footer className={`min-w-full h-[75px] border-t border-stroke-4 backdrop-blur-[7px] px-6 lg:px-[150px] xl:px-[200px] -mt-[75px] flex items-center justify-center ${textColor}`}>
                <img src={logo} alt="M2DT Logo" className='h-5 hidden md:hidden lg:block' />
                <div className="lg:flex-grow lg:flex lg:justify-center"></div>
                <div className='flex flex-row gap-2 justify-center items-center'>
                    <Link to="/privacy-policy" className="text-blue-600 hover:underline">
                        Privacy Policy
                    </Link>
                    <p>|</p>
                    <span onClick={handleOpenPopup} className="cursor-pointer text-blue-600 hover:underline">
                        Cookies
                    </span>
                </div>
            </footer>

            <CookiePopup isOpen={isPopupOpen} onClose={handleClosePopup} />
        </>
    );
}

export default Footer;