import React from 'react';

const SectionTitle = ({ title, className }) => {
    return (
        <div className={`gradient1 flex items-center justify-center w-fit rounded-full px-4 py-[10px] ${className}`}>
            <span className='supertitle text-white'>
                {title}
            </span>
        </div>
    );
}

export default SectionTitle;
