import React from 'react';
import MainButton from '../../components/MainButton';
import igradientcircle from '../../assets/icons/common/gradientcircle.svg';

const Middle = () => {
    return (
        <section className='relative -mt-5 overflow-hidden'>
            <div className='bg-blue w-full h-[600px] pb-20 pt-20 flex justify-center items-center relative z-0'>
                <div className='flex flex-col items-center relative z-20'>
                    <div className='text-white text-center mb-10'>
                        <h1 className='heading2'>
                            From <span className='start-gradient'>start</span> to <span className='finish-gradient'>finish</span>, we're
                        </h1>
                        <h1 className='heading2'>committed to turning your</h1>
                        <h1 className='heading2'>ideas into reality.</h1>
                    </div>
                    <MainButton text='Discover Our Expertise' scrollToId="contact-us" showIcon={true} className='border-stroke-5 bg-bgbtn' />
                </div>

                {/* Circle in Top Left Corner */}
                <img
                    src={igradientcircle}
                    alt="Circle"
                    className="absolute top-0 rotate-0 left-0 transform translate-x-[-70%] translate-y-[-30%] max-w-screen-lg lg:w-3/4"
                    style={{ zIndex: 0 }}
                />

                {/* Circle in Bottom Right Corner */}
                <img
                    src={igradientcircle}
                    alt="Circle"
                    className="absolute bottom-0 right-0 transform translate-x-[60%] translate-y-[40%] max-w-screen-lg lg:w-3/4"
                    style={{ zIndex: 0 }} 
                />
            </div>
        </section>
    );
}

export default Middle;
