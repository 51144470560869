import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const CookiePopup = ({ isOpen, onClose }) => {
    const cookiePopupRef = useRef(null);
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        onClose();
        navigate(path);
    };

    const popupVariants = {
        hidden: { opacity: 0, y: '-50%' },
        visible: { opacity: 1, y: '0%' },
        exit: { opacity: 0, y: '50%' },
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (cookiePopupRef.current && !cookiePopupRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [cookiePopupRef, onClose]);

    return (
        <AnimatePresence>
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <motion.div
                        className="bg-white p-6 rounded-3xl shadow-md w-11/12 md:w-1/2 lg:w-1/3"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={popupVariants}
                        transition={{ duration: 0.3 }}
                        ref={cookiePopupRef}
                    >
                        <h2 className="heading3 mb-4">Cookie Policy</h2>
                        <p className="mb-4 text-normal">
                            This website uses cookies to ensure you get the best experience on our website.
                            <span 
                                onClick={() => handleNavigate("/privacy-policy")} 
                                className="text-darkblue hover:underline cursor-pointer ml-2"
                            >
                                Learn more
                            </span>
                        </p>
                        <button onClick={onClose} className="bg-blue text-white px-5 py-3 rounded-[100px]">
                            Close
                        </button>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default CookiePopup;
