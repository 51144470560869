import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import istar from '../assets/icons/common/star.svg';

const Highlight = () => {
  const scrollRef = useRef(null);

  return (
    <div ref={scrollRef}>
      <motion.div
        className='w-fit h-fit flex flex-col items-center gap-5 justify-center mx-auto'
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ root: scrollRef }}
        transition={{ duration: 0.5, ease: "easeInOut", delay: 0.1 }}
      >
        <div className='bg-offwhite rounded-full p-3'>
          <img src={istar} alt="Highlight" className='w-8 h-8' />
        </div>
        <p className='text-center text-normal max-w-md'>We're always learning new technologies to make sure we can solve any challenge you bring us.</p>
      </motion.div>
    </div>
  )
}

export default Highlight