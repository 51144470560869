import React from 'react';
import ErrorMessage from '../ErrorMessage';

const FormInput = ({ 
    type = 'text', 
    value, 
    onChange, 
    placeholder, 
    className, 
    error,
    name, 
}) => {
    return (
        <div className={`flex flex-col transition-all ease-in-out ${className}`}>
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={`border bg-darkblue rounded-3xl pl-5 pt-4 pb-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-500 ${error ? 'border-red-500' : 'border-stroke-1'}`}
            />
            <ErrorMessage error={error} />
        </div>
    );
};

export default FormInput;
