import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import imore from '../../assets/icons/common/more.svg';
import iless from '../../assets/icons/common/less.svg';

const itemVariants = {
  open: {
    opacity: 1,
    height: "auto",
    transition: { type: "spring", stiffness: 400, damping: 24 }
  },
  closed: {
    opacity: 0,
    height: 0,
    transition: { duration: 0.2 }
  }
};

const ApproachCard = ({ id, title, description }) => {
  const scrollRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleDescription = () => {
    setIsOpen(prev => !prev);
  };

  const getClassForNumber = (id) => {
    if (id % 3 === 1) {
      return 'first';
    } else if (id % 3 === 2) {
      return 'second';
    } else {
      return 'third';
    }
  };

  return (
    <div ref={scrollRef} className='h-full'>
      <motion.div
        className='px-8 py-8 bg-offwhite rounded-3xl min-h-full flex flex-col items-start relative overflow-hidden'
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ root: scrollRef }}
        transition={{ duration: 0.5, ease: "easeInOut", delay: 0.1 }}
      >
        <div className='flex items-start'>
          <div className='z-20 min-w-24 mr-2 h-full overflow-x-clip flex items-center justify-center relative'>
            <span
              className={`${getClassForNumber(id)} number flex-shrink-0 absolute min-w-24`}
              style={{
                top: '50%',
                left: id === 1 ? '20%' : '25%',
                transform: 'translate(-50%, -20%)'
              }}
            >
              0{id}
            </span>
          </div>

          <div className='flex flex-col justify-center h-full'>
            <p className='text-left heading4 break-words'>{title}</p>

            <p
              className='flex flex-row items-center gap-2 mt-4 cursor-pointer medium-link'
              onClick={toggleDescription}
            >
              Read more
              {isOpen ? <img src={iless} alt="Toggle" className='w-6 h-6' /> : <img src={imore} alt="Toggle" className='w-6 h-6' />}
            </p>
          </div>
        </div>

        <motion.div
          variants={itemVariants}
          initial="closed"
          animate={isOpen ? "open" : "closed"}
          style={{ overflow: "hidden" }}
        >
          <div className='pt-5 w-full h-fit'>
            <p className='text-small text-left'>{description}</p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ApproachCard;
