import React from 'react';
import SectionTitle from '../../components/SectionTitle';
import Highlight from '../../components/Highlight';
import igolang from '../../assets/icons/technologies/golang.svg';
import iflutter from '../../assets/icons/technologies/flutter.svg';
import icpp from '../../assets/icons/technologies/cpp.svg';
import inodejs from '../../assets/icons/technologies/nodejs.svg';
import ipython from '../../assets/icons/technologies/python.svg';
import ireact from '../../assets/icons/technologies/react.svg';
import TechCard from '../../components/Cards/TechCard';

const Technology = () => {

    const techData = [
        {
            icon: igolang,
            title: 'Go',
            description: 'Developing efficient, reliable, and scalable server-side applications'
        },
        {
            icon: iflutter,
            title: 'Flutter',
            description: 'Creating native applications for mobile, web, and desktop from a single codebase'
        },
        {
            icon: ireact,
            title: 'React & Next.js',
            description: 'For building dynamic and responsive UI, enabling rendering and static site generation for optimal performance'
        },
        {
            icon: inodejs,
            title: 'Node.js',
            description: 'Building fast and scalable network applications'
        },
        {
            icon: ipython,
            title: 'Python',
            description: 'Prototyping and rapid development across web, data and AI applications'
        },
        {
            icon: icpp,
            title: 'C++',
            description: 'Developing fast, efficient software and complex applications'
        }
    ];

    return (
        <section id='technology'>
            <SectionTitle title='Technology' className='mb-6' />

            <div className='mb-16'>
                <div className='mb-5'>
                    <h1 className='heading2'>Modern technologies to</h1>
                    <h1 className='heading2'>build efficient solutions</h1>
                </div>

                <div className='text-normal max-w-md'>
                    <p>We use different tools to create powerful, and scalable solutions! Here's a glimpse of what we're great at.</p>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                {techData.map((service, index) => (
                    <TechCard
                        key={index}
                        icon={service.icon}
                        title={service.title}
                        description={service.description}
                    />
                ))}
            </div>
            <div className='w-full h-fit mt-8'>
                <Highlight />
            </div>
        </section>
    )
}

export default Technology