import React, { useRef } from 'react';
import { motion } from 'framer-motion';

const ServiceCard = ({ icon, title, description }) => {
  const scrollRef = useRef(null);

  return (
    <div ref={scrollRef}>
      <motion.div
        className='p-8 bg-offwhite rounded-3xl h-full w-full flex flex-col gap-9'
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ root: scrollRef }}
        transition={{ duration: 0.5, ease: "easeInOut", delay: 0.1 }}
      >
        <img src={icon} alt="" className='w-12 h-12' />

        <div className='flex flex-col gap-5'>
          <h3 className='heading3'>{title}</h3>
          <p className='text-small'>{description}</p>
        </div>
      </motion.div>
    </div>
  );
}

export default ServiceCard;
