import React from 'react';
import { useNavigate } from 'react-router-dom';
import LogoWithSlogan from './LogoWithSlogan/LogoWithSlogan';
import iclose from '../../assets/icons/common/close.svg';

const MobileNavbar = ({ links, onClose, isOpen }) => {
    const navigate = useNavigate();

    const handleLinkClick = (href) => {
        navigate('/', { state: { section: href } });
        window.location.hash = href;
        onClose();
    };

    return (
        <div
            className={`fixed top-0 right-0 h-full w-full bg-white shadow-md transform ${isOpen ? 'translate-x-0' : 'translate-x-full'
                } transition-transform duration-300 ease-in-out lg:hidden z-50`}
        >
            <button
                className="absolute top-8 right-8"
                onClick={onClose}
            >
                <img src={iclose} alt="Close" className="w-6 h-6" />
            </button>

            <ul className="flex flex-col items-center justify-center h-full space-y-8 px-6 text-xl font-medium mb-16">
                {links.map((link) => (
                    <li key={link.label}>
                        <button
                            onClick={() => handleLinkClick(link.href)}
                            className="text-gray-700 hover:text-gray-900"
                        >
                            {link.label}
                        </button>
                    </li>
                ))}
            </ul>

            <div className="absolute bottom-8 w-full flex justify-center">
                <LogoWithSlogan showSlogan={true} />
            </div>
        </div>
    );
};

export default MobileNavbar;
