import React from 'react';
import SectionTitle from '../../components/SectionTitle';
import ApproachCard from '../../components/Cards/ApproachCard';

const Approach = () => {
  const servicesData = [
    {
      id: 1,
      title: 'Discovery',
      description: `Every project starts with listening. We'll sit down with you to understand your goals, needs, and challenges. This helps us to establish a solid foundation and create a clear plan that matches your vision and aligns our expertise with your vision and objectives.`,
    },
    {
      id: 2,
      title: 'Design',
      description: `This is where your vision comes to life. We'll create mockups and wireframes to visualize ideas, gather the feedback and validate concepts. At the same time, we'll evaluate technical requirements and constraints, choose the right technology, and define the application architecture and infrastructure needed to support your solution.`,
    },
    {
      id: 3,
      title: 'MVP Scoping & Planning',
      description: `Together, we'll map out the project. We'll break it down into manageable tasks, set clear milestones and establish deadlines. In this phase, we'll define the Minimum Viable Product (MVP), establish success metrics, and plan for user testing.`,
    },
    {
      id: 4,
      title: 'Build',
      description: 'This is where we roll up our sleeves. Using agile methods, we build your solution piece by piece, and adapt swiftly to any new changes. Our approach ensures rapid progress, precision, and continuous improvement throughout the development.',
    },
    {
      id: 5,
      title: 'Collaboration',
      description: `You're part of the team. We'll keep you in the loop with regular updates and check-ins. Your feedback shapes the project at every stage.`,
    },
    {
      id: 6,
      title: 'Delivery & Knowledge Transfer',
      description: `We don't just hand over a finished product. We make sure you and your team know how to make the most of it. We're here to answer questions and provide support even after the project is complete ensuring long-term success.`,
    },
  ];

  return (
    <section id='our-approach'>
      <SectionTitle title='Our Approach' className='mb-6' />

      <div className='mb-16'>
        <div className='mb-5'>
          <h1 className='heading2'>From Concept</h1>
          <h1 className='heading2'>to Reality</h1>
        </div>

        <div className='text-normal text-black'>
          <p>We keep things simple but effective. Here's</p>
          <p>how we'll work with you:</p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5 w-full h-full">
        {servicesData.map((service) => (
          <div key={service.id} className="flex flex-col justify-between h-full">
            <ApproachCard
              id={service.id}
              title={service.title}
              description={service.description}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Approach;
