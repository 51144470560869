import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import ErrorMessage from '../ErrorMessage';
import '../../styles/CustomDatePicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import { motion, AnimatePresence } from 'framer-motion';
import iarrow from '../../assets/icons/common/arrow.svg';

const CustomDatePicker = ({ selectedDate, onDateChange, error }) => {
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [newError, setNewError] = useState(error);
    const datePickerRef = useRef(null);

    const toggleDatePicker = () => {
        setIsDatePickerOpen((prev) => !prev);
    };

    useEffect(() => {
        setNewError(error);
    }, [error]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setIsDatePickerOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [datePickerRef]);

    return (
        <div className={`relative ${newError ? 'mb-2 mt-2' : ''}`} ref={datePickerRef}>
            <div
                className={`border ${newError ? 'border-red-500' : 'border-stroke-1'} bg-darkblue rounded-3xl w-full flex justify-between items-center pl-5 pt-4 pb-4 pr-5 cursor-pointer`}
                onClick={toggleDatePicker}
            >
                <span>{selectedDate ? selectedDate.toLocaleDateString('en-GB') : 'Desired start date'}</span>
                <span className="flex items-center justify-center">
                    <img
                        src={iarrow}
                        alt="arrow"
                        className={`min-w-6 w-6 h-6 transition-transform duration-300 ${isDatePickerOpen ? 'rotate-180' : 'rotate-0'}`}
                    />
                </span>
            </div>


            <AnimatePresence>
                {isDatePickerOpen && (
                    <motion.div
                        className="absolute z-10 mt-1 rounded-3xl w-full"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => {
                                onDateChange(date);
                                setIsDatePickerOpen(false);
                                setNewError(null);
                            }}
                            inline
                            className="border border-stroke-1 pl-5 pt-4 pb-4 pr-8 bg-darkblue rounded-lg w-full appearance-none"
                            dateFormat="dd.MM.yyyy."
                            minDate={new Date()}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
            <ErrorMessage error={newError} className={'absolute'} />
        </div>
    );
};

export default CustomDatePicker;
